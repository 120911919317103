/* minee */

/* -text: #181b19;
--background: #ffffff;
--primary: #8C171B;
--secondary: #e0dddc;
--accent: #34a853; */

body{
   background-color: #E8F2EC;
}